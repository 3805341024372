import TeamTimeText from './TeamTimeText.vue';
import TeamTimeNumber from './TeamTimeNumber.vue';
import TeamTimeCellToggler from './TeamTimeCellToggler.vue';


const EDITABLE_FIELDS = [
    'workloadEstimated',
    'rafEstimated',
    'description',
    'billing',
    'todo',
    'comments',
];


export default {
    name: 'TeamTimeProject',
    components: {
        TeamTimeText,
        TeamTimeNumber,
        TeamTimeCellToggler,
    },
    props: {
        teamTime: {},
        simpleMode: false,
    },
    data() {
        return {
            tt: { ...this.teamTime }, // Editable teamTime
        };
    },
    computed: {
        raf() {
            if (!this.teamTime.raf) return '';
            return Math.round((this.teamTime.raf * 10) / 8) / 10;
        },
        workload() {
            if (!this.teamTime.workloadPassed) return '';
            return Math.round((this.teamTime.workloadPassed * 10) / 8 / 4) / 10;
        },
    },
    watch: {
        tt: {
            deep: true,
            handler(tt) {
                const changeset = Object.fromEntries(EDITABLE_FIELDS.filter(field => tt[field] !== this.teamTime[field]).map(field => [field, tt[field]]));
                let promise;
                if (changeset.hasOwnProperty('description')) {
                    promise = this.$store.dispatch('Project/edit/update', {
                        projectId: this.teamTime.projectId,
                        project: { description: changeset.description },
                    });
                } else {
                    const payload = { ...changeset, id: this.teamTime.id, userId: this.teamTime.userId, projectId: this.teamTime.projectId, };
                    promise = this.$store.dispatch('TeamTime/upsertProject', payload);
                }
                promise.then(() => { this.$emit('submit'); });
            }
        }
    },
}
