import dayjs from 'dayjs';
import { mapGetters } from 'vuex';
import Multiselect from 'vue-multiselect';
import { userIsAtLeastExpert } from '@/app/helpers';
import ResourceSelect from "@/views/common/ResourceSelect.vue";
import GroupSelect from "@/views/common/GroupSelect.vue";
import RangeSelect from "@/views/common/RangeSelect.vue";
import MyEntries from "@/views/resource/MyEntries.vue";
import MyNumbers from "@/views/resource/MyNumbers.vue";
import IconDownload from "@/icons/download-solid.svg";
import TeamTimeUser from "./TeamTimeUser.vue";


export default {
    name: "TeamTime",
    components: {
        Multiselect,
        ResourceSelect,
        GroupSelect,
        RangeSelect,
        TeamTimeUser,
        MyEntries,
        MyNumbers,
        IconDownload,
    },
    data() {
        return {
            group: null,
            user: null,
            userTeamTimes: [],
            simpleMode: false,
            showEntries: false,
            range: [dayjs().subtract(6, 'month').toDate(), new Date()],
        };
    },
    computed: {
        // @ts-ignore
        ...mapGetters({
            resources: 'Resource/list/resourcesAll',
            groups: 'Group/edit/groups',
            loggedUser: 'Resource/auth/user',
        }),
        groupResources() {
            if (!this.group) return [];
            return this.resources.filter((u) => this.group.userIds.includes(u.id.toString()));
        },
        selectedResourceIds() {
            if (this.user) return [this.user.id];
            if (this.group) return this.groupResources.map(u => u.id);
            return [];
        },
    },
    methods: {
        refresh([userTeamTimes, leaves]) {
            userTeamTimes.forEach((data) => {
                data.user = this.resources.find(user => user.id == data.userId);
                data.leaves = leaves.find(leave => leave.user_id == data.userId);
            });
            if (userTeamTimes.length === 1) userTeamTimes[0].startOpen = true;
            this.userTeamTimes = userTeamTimes;
        },
        refreshForGroup() {
            if (!this.group) return;
            this.$store.dispatch('TeamTime/userForGroup', this.group.id).then(this.refresh);
        },
        refreshForUser() {
            if (!this.user) return;
            this.$store.dispatch('TeamTime/userForUser', this.user.id).then(this.refresh);
        },
        refreshForGuess() {
            if (this.showEntries) return;
            if (this.user) return this.refreshForUser();
            if (this.group) return this.refreshForGroup();
        },
        myNumbersDetail() {
            this.$refs.myNumbers.myNumbersDetail();
        },
    },
    watch: {
        group(group) {
            this.userTeamTimes = [];
            if (!group) return;
            this.user = null;
            this.refreshForGuess();
        },
        user(user) {
            this.userTeamTimes = [];
            if (user && !this.groupResources.includes(user)) this.group = null;
            this.refreshForGuess();
        },
        showEntries() {
            this.refreshForGuess();
        },
    },
    mounted() {
        this.$store.dispatch('Group/edit/getList');
        this.$store.dispatch('Project/list/getList');
        this.user = this.loggedUser;
        if (!userIsAtLeastExpert(this.loggedUser)) {
            this.simpleMode = true;
        }
    }
}
