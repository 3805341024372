















export default {
  name: "TeamTimeNumber",
  props: {
    value: {},
  },
  methods: {
    input() {
      const value = this.$refs.input.value;
      if ((this.value || '') !== value) this.$emit("input", value);
      this.close();
    },
    close() {
      this.$parent.$emit("close");
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.input.focus();
      this.$refs.input.select();
    });
  },
};
