export function leaveReducer(acc, leave) {
    return acc + parseFloat(leave.hours);
}
function leaveReducerForStatus(acc, leave, status) {
    return acc + (leave.status === status.toString() ? parseFloat(leave.hours) : 0);
}
export function pendingReducer(acc, leave) {
    return leaveReducerForStatus(acc, leave, 0);
}
export function submittedReducer(acc, leave) {
    return leaveReducerForStatus(acc, leave, 1);
}
export function validatedReducer(acc, leave) {
    return leaveReducerForStatus(acc, leave, 2);
}
export function showDays(hours) {
    return Math.round(hours / 8 * 10) / 10;
}


export function issueLeavesReducer(r, a) {
    r[a.issue_id] = r[a.issue_id] || { subject: a.subject, leaves: [] };
    r[a.issue_id].leaves.push(a);
    return r;
}


export function indexLeavesByIssueResource(leaves, resources) {
    const indexedData = {};
    for (let key in leaves) {
        const leave = leaves[key];
        const resourceName = resources.get(parseInt(leave.user_id));
        indexedData[leave.issue_id] = indexedData[leave.issue_id] || { subject: leave.subject, resources: {} };
        const issueResources = indexedData[leave.issue_id].resources;
        issueResources[leave.user_id] = issueResources[leave.user_id] || { name: resourceName, leaves: [] };
        issueResources[leave.user_id].leaves.push(leave);
    }
    return indexedData;
}


function getISODateString(date) {
    const y = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
    const m = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(date);
    const d = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);
    return `${y}-${m}-${d}`;
}
export function getBusinessDates(startDate:Date, endDate:Date, publicHolidays:string[], onlyForDays = null) {
    let businessDates = [];
    let curDate = new Date(startDate.valueOf());
    while (curDate <= endDate) {
        const dayOfWeek = curDate.getDay();
        const dateString = getISODateString(curDate);
        curDate.setDate(curDate.getDate() + 1);
        if (onlyForDays && !onlyForDays.includes(dayOfWeek)) continue;
        if (publicHolidays.indexOf(dateString) !== -1) continue;
        if (dayOfWeek === 6 || dayOfWeek === 0) continue;
        businessDates.push(dateString);
    }
    return businessDates;
}


export function leaveHoursForIndex(leave, index) {
    const HALF_DAY = 4;
    const FULL_DAY = 8;
    if (!leave.startHalf && !leave.endHalf) return FULL_DAY;
    if (leave.dates.length === 1) return (leave.startHalf != leave.endHalf) ? HALF_DAY : FULL_DAY;
    if (index == 0) return leave.startHalf ? HALF_DAY : FULL_DAY;
    if (index == leave.dates.length - 1) return leave.endHalf ? HALF_DAY : FULL_DAY;
    return FULL_DAY;
}


export function getRemainingLeaves(counters) {
    if (!counters) return {}
    const c = Object.fromEntries(Object.keys(counters).map((k) => {
        const i = parseFloat(counters[k]);
        return [k, isNaN(i) ? null : i];
    }));
    const theoreticCpn = c.leaves_cpn - c.spent_cpn;
    const cpn = Math.max(theoreticCpn, 0);
    const cp = c.leaves_cp - c.spent_cp + Math.min(theoreticCpn, 0);
    return {
        cp,
        cpn,
        rtt: c.leaves_rtt === null ? null : c.leaves_rtt - c.spent_rtt,
        recup: c.leaves_recup === null ? null : c.leaves_recup - c.spent_recup,
    };
}
