import dayjs from 'dayjs';
import { mapGetters } from 'vuex';
import {
    Chart,
    PieController,
    ArcElement,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import tippy, { delegate as tippyDelegate } from "tippy.js";
import * as Config from "@/config/constants";
import Tween from "@/views/common/Tween.vue";
import IconInfo from "@/icons/info-circle-solid.svg";
import { processXlsxResponse } from '@/app/helpers';


Chart.register(
    PieController,
    ArcElement,
    ChartDataLabels,
);
let chart;


const statuses = {
    sales: { color: '#d90d80', label: 'Commerce', textColor: '#fff' },
    billed: { color: '#316cb4', label: 'Client', textColor: '#fff' },
    interco: { color: '#6497d5', label: 'Intercontrat', textColor: '#fff' },
    planned: { color: '#ee834d', label: 'Planifié', textColor: '#fff' },
    leaves: { color: '#36b04c', label: 'Congés', textColor: '#fff' },
    notEntered: { color: '#ddd', label: 'RAA', textColor: '#777', tooltip: 'Reste à affecter' },
};
function updateChart(chart, data) {
    data.datasets[0].data.forEach((nb, index) => {
        chart.data.datasets[0].data[index] = nb;
    });
    chart.update();
}
function renderChart(chartElm, entriesByStatus) {
    if (entriesByStatus.length === 1 && entriesByStatus[0] === 'test') return;
    const labels = entriesByStatus.map(e => e.props.label);
    const datasets = [{
        data: entriesByStatus.map(e => e.days),
        backgroundColor: entriesByStatus.map(e => e.props.color),
        datalabels: { color: entriesByStatus.map(e => e.props.textColor) },
    }];
    const data = { labels, datasets };
    if (chart) return updateChart(chart, data);
    chart = new Chart(chartElm, {
        type: 'pie',
        data,
        options: {
            // responsive: true,
            aspectRatio: 1,
            animation: { duration: 0 },
            plugins: {
                datalabels: {
                    font: { size: 20, },
                    formatter: (value) => value ? Math.round(value) : null,
                },
            },
        },
    });
}


function decimal1(num) {
    return Math.round(num * 10) / 10
}


export default {
    props: {
        userIds: {
            default: 'my'
        },
        range: {
            required: true,
        },
    },
    components: {
        Tween,
        IconInfo,
    },
    data() {
        return {
            numbersAll: [],
            statuses,
            selectedUser: null,
        };
    },
    computed: {
        // @ts-ignore
        ...mapGetters({
            resources: 'Resource/list/resourcesAll',
        }),
        enteredVsWorking() {
            if (!this.numbersMy.working) return null;
            return Math.round(this.numbersMy.entered / this.numbersMy.working * 100);
        },
        enteredVsBilled() {
            return Math.round(this.numbersMy.billed / this.numbersMy.entered * 100);
        },
        entriesByStatus() {
            const nm = this.numbersMy;
            const entriesByStatus = [
                { perc: 0, props: statuses.sales, days: nm.sales },
                { perc: 0, props: statuses.billed, days: nm.billed },
                { perc: 0, props: statuses.interco, days: nm.entered - nm.leaves - nm.billed - nm.sales - nm.planned },
                { perc: 0, props: statuses.planned, days: nm.planned },
                { perc: 0, props: statuses.leaves, days: nm.leaves },
                { perc: 0, props: statuses.notEntered, days: Math.max(nm.working - nm.entered, 0) },
            ];
            entriesByStatus.forEach(e => {
                if (e.days / nm.working < .01) e.days = null;
                if (e.props === statuses.leaves) return;
                e.perc = e.days / (nm.working - nm.leaves);
            });
            return entriesByStatus;
        },
        legendEntries() {
            return [...this.entriesByStatus].sort((a, b) => (a.perc < b.perc) ? 1 : (a.perc > b.perc) ? -1 : 0);
        },
        numbersMy() {
            if (this.selectedUser) return this.numbersAll.find(na => na.user === this.selectedUser);
            const numbersSum = this.numbersAll.reduce((acc, nums) => {
                Object.keys(nums).forEach(key => {
                    if (key === 'user') return;
                    acc[key] = (acc[key] || 0) + nums[key];
                });
                return acc;
            }, {});
            numbersSum.coef /= this.numbersAll.length;
            return numbersSum;
        },
        formattedRange() {
            return this.range.map(d => dayjs(d).format(Config.DATE_FORMAT));
        },
    },
    methods: {
        decimal1,
        refresh(download = false) {
            const payload = {
                userIds: this.userIds,
                from: this.formattedRange[0],
                to: this.formattedRange[1],
            };
            const promise = this.$store.dispatch('Dashboard/list/getMyNumbers' + (download ? 'Details' : ''), payload);
            if (download) return promise.then(processXlsxResponse('detail.xlsx'));
            return promise.then(numbers => {
                this.numbersAll = numbers.map(nums => {
                    const user = this.resources.find(r => r.id === parseInt((nums.user_id)));
                    return {
                        user,
                        working: parseFloat(nums.working_days || 0) - parseFloat(nums.public_holidays || 0),
                        entered: parseFloat(nums.entered_hours || 0) / 8,
                        planned: parseFloat(nums.planned_hours || 0) / 8,
                        leaves: parseFloat(nums.leave_hours || 0) / 8,
                        billed: parseFloat(nums.billed_hours || 0) / 8,
                        sales: parseFloat(nums.sales_hours || 0) / 8,
                        coef: parseFloat(nums.coef || 0),
                    };
                });
                renderChart(this.$refs.chart, this.entriesByStatus);
            });
        },
        myNumbersDetail() {
            this.refresh(true);
        },
    },
    watch: {
        userIds() {
            this.refresh();
        },
        range() {
            this.refresh();
        },
        selectedUser() {
            renderChart(this.$refs.chart, this.entriesByStatus);
        },
    },
    mounted() {
        this.refresh().then(() => {
            // To prevent the initial animation
            delete chart.options.animation.duration;
        });
        tippy(this.$refs.percTip);
        tippyDelegate(this.$refs.tbody, { target: 'abbr' });
        chart = null;
    }
}
