




import Tween from "@tweenjs/tween.js";

function animate() {
  requestAnimationFrame(animate);
  Tween.update();
}

function precision(a) {
  if (!isFinite(a)) return 0;
  var e = 1, p = 0;
  while (Math.round(a * e) / e !== a) { e *= 10; p++; }
  return p;
}

function tween(from, to, duration = 1000) {
    const tw = new Tween.Tween(from);
    tw.easing(Tween.Easing.Exponential.Out);
    tw.to(to, duration).start();
    animate();
}

export default {
  props: {
    value: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      tweened: { value: 0 },
    };
  },
  computed: {
    scientificPrecision() {
      // This prevents overflowing digits flickering during the animation
      return Math.pow(10, precision(this.value));
    },
    val() {
      return Math.round(this.tweened.value * this.scientificPrecision) / this.scientificPrecision;
    }
  },
  methods: {
    tween(value) {
      tween(this.tweened, { value }, 1000);
    },
    retween() {
      this.tweened = { value: 0 };
      this.tween(this.value);
    },
  },
  watch: {
    value(newValue) {
      this.tween(newValue);
    },
  },
}
