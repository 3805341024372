import { mapGetters } from 'vuex';
import { delegate as tippyDelegate } from "tippy.js";
import FlatPickr from 'vue-flatpickr-component';
import { French } from 'flatpickr/dist/l10n/fr.js';
import TeamTimeProject from './TeamTimeProject.vue';
import TeamTimeNumber from './TeamTimeNumber.vue';
import TeamTimeText from './TeamTimeText.vue';
import TeamTimeCellToggler from './TeamTimeCellToggler.vue';
import * as Redmine from '@/config/redmine-constants';
import { getRemainingLeaves } from '../dashboard/dashboard-helpers';


const EDITABLE_FIELDS = [
    'reviewedAt',
    'workloadEstimated',
    'wishes',
    'todo',
    'comments',
];

export default {
    name: 'TeamTimeUser',
    components: {
        TeamTimeProject,
        TeamTimeNumber,
        TeamTimeText,
        TeamTimeCellToggler,
        FlatPickr,
    },
    props: {
        teamTime: {},
        simpleMode: false,
    },
    data() {
        return {
            open: false,
            projectTeamTimes: [],
            dateConfig: {
                locale: French,
            },
            tt: { ...this.teamTime }, // Editable teamTime
            roles: [Redmine.ROLE_PROJECT_MANAGER],
            selectableRoles: {
                [Redmine.ROLE_PROJECT_MANAGER]: Redmine.PROFILE_PROJECT_MANAGER,
                [Redmine.ROLE_CONSULTANT]: Redmine.PROFILE_CONSULTANT,
                [Redmine.ROLE_PROJECT_MANAGER_ASSISTANT]: Redmine.PROFILE_PROJECT_MANAGER_ASSISTANT,
                [Redmine.ROLE_PROJECT_DIRECTOR]: Redmine.PROFILE_PROJECT_DIRECTOR,
            }
        };
    },
    computed: {
        // @ts-ignore
        ...mapGetters({
            resources: 'Resource/list/resourcesAll',
            projects: 'Project/list/projects',
        }),
        workload() {
            const workloadNoInternal = this.teamTime.workloadPassed - this.teamTime.internalPassed;
            if (!workloadNoInternal) return '';
            return Math.round((workloadNoInternal * 10) / 8 / 4) / 10;
        },
        workloadTooltip() {
            const including = [];
            const excluding = [];
            if (parseInt(this.teamTime.leavesPassed)) including.push('<b>' + Math.round((this.teamTime.leavesPassed * 10) / 8 / 4) / 10 + '</b> d/w holidays');
            if (parseInt(this.teamTime.internalPassed)) excluding.push('<b>' + Math.round((this.teamTime.internalPassed * 10) / 8 / 4) / 10 + '</b> d/w internal');
            const returning = [];
            if (including.length) returning.push('Including ' + including.join(', '));
            if (excluding.length) returning.push('Excluding ' + excluding.join(', '));
            return returning.length ? returning.join('<br />') : null;
        },
        remainingLeaves() {
            if (!this.teamTime.leaves) return;
            return getRemainingLeaves(this.teamTime.leaves);
        },
        leaves() {
            if (!this.remainingLeaves) return;
            return Math.round((this.remainingLeaves.cp + this.remainingLeaves.cpn + this.remainingLeaves.rtt + this.remainingLeaves.recup) * 10) / 10;
        },
        leavesIncluding() {
            if (!this.remainingLeaves) return;
            return [
                [Math.round(this.remainingLeaves.cpn * 10) / 10, 'CP n-1'],
                [Math.round(this.remainingLeaves.cp * 10) / 10, 'CP'],
                [Math.round(this.remainingLeaves.rtt * 10) / 10, 'RTT'],
                [Math.round(this.remainingLeaves.recup * 10) / 10, 'Récup'],
            ].filter(leave => leave[0]).map(leave => '<strong>' + leave[0] + '</strong> ' + leave[1]).join(', ');
        },
    },
    methods: {
        getProjectsData() {
            this.$store
                .dispatch('TeamTime/projectForUser', { userId: this.teamTime.user.id, roles: this.roles })
                .then(async (projectTeamTimes) => {
                    projectTeamTimes.forEach((data) => {
                        data.project = this.projects.find((proj) => proj.id == data.projectId);
                        data.dps = data.project.dp_ids.map(id => this.resources.find(res => res.id == id));
                        data.rts = data.project.rt_ids.map(id => this.resources.find(res => res.id == id));
                        data.raf = 0;
                    });
                    this.projectTeamTimes = projectTeamTimes;
                    if (this.simpleMode) return;
                    for (const data of this.projectTeamTimes) {
                        const totals = await this.$store.dispatch('Project/list/getTotalsCached', data.projectId);
                        data.raf = totals.agg_raf;
                    }
                });
        },
        openProjects() {
            this.open = !this.open;
            if (!this.open) return;
            if (this.projectTeamTimes.length) return;
            this.getProjectsData();
        },
        submitted() {
            this.getProjectsData();
            this.$emit('submit');
        },
    },
    watch: {
        tt: {
            deep: true,
            handler(tt) {
                const changeset = Object.fromEntries(EDITABLE_FIELDS.filter(field => tt[field] !== this.teamTime[field]).map(field => [field, tt[field]]));
                const payload = { ...changeset, id: this.teamTime.id, userId: this.teamTime.userId, }
                this.$store.dispatch('TeamTime/upsertUser', payload).then(() => {
                    this.$emit('submit');
                });
            }
        }
    },
    mounted() {
        if (this.teamTime.startOpen) this.openProjects();
        tippyDelegate(this.$refs.user, {
            multiple: true,
            target: '.user_workload',
            content: this.workloadTooltip,
            onTrigger: (instance) => {
                if (!this.workloadTooltip) instance.disable();
            },
            onUntrigger: (instance) => {
                instance.enable();
            },
        });
        tippyDelegate(this.$refs.user, {
            multiple: true,
            target: '.user_leaves',
            content: this.leavesIncluding,
            onTrigger: (instance) => {
                if (!this.leavesIncluding) instance.disable();
            },
            onUntrigger: (instance) => {
                instance.enable();
            },
        });
        tippyDelegate(this.$refs.user, {
            multiple: true,
            target: '.projects_workload',
            content: 'Past 4 weeks average'
        });
    }
};
