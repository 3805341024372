<template>
  <div @click="open = true" class="cell_toggler">
    <div v-if="!open" class="value"><slot></slot></div>
    <div v-if="open" class="cell_edit"><slot name="edit"></slot></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      open: false,
    };
  },
  mounted() {
    this.$on("close", function () {
      this.open = false;
    });
  },
};
</script>
