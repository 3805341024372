import { delegate as tippyDelegate } from "tippy.js";
import IconTree from "@/icons/sitemap-solid.svg";
import { FormatHours2Days } from "@/utils/vue-additions";
import { totalsCoef } from "@/utils/timentries";


export default {
    props: {
        entries: {
            required: true,
        },
        globals: {
            required: true,
        },
        open: {
            default: false,
        },
        mode: {
            required: true,
        },
        showLed: {
            default: true,
        },
        dimmed: {
            default: false,
        },
    },
    components: {
        IconTree,
    },
    computed: {
        ledTip() {
            if (!this.globals) return false;
            const PECMHours = parseFloat(this.globals.agg_pecm_hours);
            let tip = `Coef: ${ Math.round(this.coef * 100) / 100 }`;
            if (PECMHours) tip += '<br />PECM: ' + FormatHours2Days(PECMHours.toString());
            return tip;
        },
        coef() {
            if (!this.globals) return 0;
            return totalsCoef(this.globals);
        },
        ledCss() {
            return this.coef < 1 ? 'orange' : 'green';
        },
    },
    mounted() {
        const vm = this;
        tippyDelegate(vm.$refs.row, {
            target: '.led',
            onShow(instance) {
                if (!vm.ledTip) return false;
                instance.setContent(vm.ledTip);
            }
        });
    },
}
