import { mapGetters } from "vuex";
import Multiselect from "vue-multiselect";
import { filterResourceByName } from "@/app/helpers";

export default {
  name: "ResourceSelect",
  components: { Multiselect },
  props: {
    value: {},
    all: { // Shows all resources including "hidden RM" (overrides active)
      default: false,
    },
    active: { // Show only active resources
      default: false,
    },
    id: {
      default: "selected_resource",
    },
    allowEmpty: {
      default: true,
    },
    showClear: {
      default: false,
    },
    multiple: {
      default: false,
    },
    oneliner: {
      default: false,
    },
    placeholder: {
      default: "Resource",
    },
    disabled: {
      default: false,
    },
  },
  computed: {
    // @ts-ignore
    ...mapGetters({
      storeResourcesAll: "Resource/list/resourcesAll",
      storeResourcesActive: "Resource/list/resourcesActive",
      storeResources: "Resource/list/resources",
    }),
    resources() {
      return this.all
        ? this.storeResourcesAll
        : this.active
        ? this.storeResourcesActive
        : this.storeResources;
    },
  },
  data() {
    return {
      resourcesFiltered: [],
    };
  },
  methods: {
    searchResources(search) {
      this.resourcesFiltered = search
        ? this.resources.filter((resource) =>
            filterResourceByName(resource, search)
          )
        : this.resources;
    },
    input(newValue) {
      this.$emit("input", newValue);
    },
  },
  mounted() {
    this.resourcesFiltered = this.resources;
  },
};
