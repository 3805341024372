<template>
  <div class="d-flex flex-wrap" :style="{ gap: '7px' }">
    <button
      :class="[
        'btn',
        'group',
        group === value ? 'btn-dark' : 'btn-light',
      ]"
      v-for="group in groups"
      :key="group.id"
      @click="input(group)"
    >
      {{ group.name }}
    </button>
    <button v-if="value" class="btn" @click="input(null)">&times;</button>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "GroupSelect",
  props: {
    value: {},
  },
  computed: {
    // @ts-ignore
    ...mapGetters({
      groups: "Group/edit/groups",
    }),
  },
  methods: {
    input(group) {
      this.$emit("input", this.value === group ? null : group);
    },
  },
  mounted() {
    this.$store.dispatch("Group/edit/getList");
  },
};
</script>
