import dayjs from 'dayjs';
import tippy from "tippy.js";
import * as Config from "@/config/constants";
import MyEntriesRow from "./MyEntriesRow.vue";
import Tween from "@/views/common/Tween.vue";
import IconInfo from "@/icons/info-circle-solid.svg";
import { ADMINISTRATIVE_PROJECT } from '@/config/redmine-constants';
import { ProjectTotals } from '@/app/types/interfaces';


function sumTotalsData(rows: ProjectTotals[]) {
    const projData:any = rows.reduce((acc, nums) => {
        Object.keys(nums).forEach(key => {
            if (['id', 'name'].includes(key)) return;
            const num = typeof nums[key] === 'string' ? parseFloat(nums[key]) : (nums[key] || 0);
            acc[key] = (acc[key] || 0) + num;
        });
        return acc;
    }, {});
    projData.versions = rows;
    return projData;
}


const MAX_VISIBLE_PROJS_BY_DEFAULT = 6;


export default {
    props: {
        userIds: {
            default: 'my'
        },
        range: {
            required: true,
        },
    },
    components: {
        MyEntriesRow,
        IconInfo,
        Tween,
    },
    data() {
        return {
            data: [],
            entriesMyMore: false,
            entriesExpandProjectId: null,
            entriesMyProject: [],
            projectsData: [],
            MAX_VISIBLE_PROJS_BY_DEFAULT,
            ADMINISTRATIVE_PROJECT,
            forcingCache: false,
        };
    },
    computed: {
        entriesMy() {
            const entriesMy = this.data.map(entry => ({
                id: entry.project_id,
                name: entry.project_name,
                passedHours: entry.passed_hours,
            }));
            if (this.entriesMyMore) return entriesMy;
            return entriesMy.splice(0, MAX_VISIBLE_PROJS_BY_DEFAULT);
        },
        projectsMy() {
            return Object.fromEntries(this.entriesMy.map(entry => {
                const totals = this.projectsData.find(pd => pd[0] == entry.id);
                return totals || [entry.id, {}];
            }));
        },
        versionsMy() {
            if (!this.entriesExpandProjectId) return [];
            const projectExpanded = this.projectsMy[this.entriesExpandProjectId];
            if (!this.entriesMyProject[0].id) return [];
            return Object.fromEntries(this.entriesMyProject.map(entry => {
                const totals = projectExpanded.versions.find(v => v.id == entry.id);
                return [entry.id, totals || {}];
            }));
        },
        formattedRange() {
            return this.range.map(d => dayjs(d).format(Config.DATE_FORMAT));
        },
    },
    methods: {
        refreshMyEntries() {
            this.entriesExpandProjectId = null;
            this.refreshProjectEntries();
            const payload = {
                userIds: this.userIds,
                from: this.formattedRange[0],
                to: this.formattedRange[1],
            }
            this.$store.dispatch('Dashboard/list/getMyEntries', payload).then(my_entries => {
                this.data = my_entries;
            });
        },
        refreshProjectEntries() {
            if (!this.entriesExpandProjectId) return this.entriesMyProject = null;
            this.entriesMyProject = [{ version: 'Chargement...' }];
            const payload = {
                userIds: this.userIds,
                from: this.formattedRange[0],
                to: this.formattedRange[1],
                projectId: this.entriesExpandProjectId,
            }
            this.$store.dispatch('Dashboard/list/getMyEntriesForProject', payload).then(my_entries_for_project => {
                this.entriesMyProject = my_entries_for_project.map(versionEntry => ({
                    id: versionEntry.version_id,
                    name: versionEntry.version_name,
                    passedHours: versionEntry.passed_hours,
                    open: versionEntry.version_open,
                }));
            });
        },
        expandEntriesMy(projectId) {
            this.entriesExpandProjectId = this.entriesExpandProjectId === projectId ? null : projectId;
            this.refreshProjectEntries();
        },
        forceProjectCache(projectId) {
            const projectDataIndex = this.projectsData.findIndex(pd => pd[0] == projectId);
            if (projectDataIndex === -1 || this.forcingCache) return;
            this.forcingCache = true;
            this.$store.dispatch('Project/list/getTotalsCachedForced', projectId).then(totals => {
                this.projectsData[projectDataIndex] = [totals.id, totals];
                // Forcing projectsMy to update
                const data = this.data;
                this.data = [];
                this.data = data;
                const vm = this;
                setTimeout(() => {
                    vm.forcingCache = false;
                }, 2000);
            });
        },
    },
    watch: {
        entriesMy() {
            for (let i in this.entriesMy) {
                const projectId = this.entriesMy[i].id;
                if (this.projectsData.find(pd => pd[0] == projectId)) continue;
                this.$store.dispatch('Project/list/getTotalsCached', projectId).then(totals => {
                    this.projectsData.push([projectId, sumTotalsData(totals)]);
                });
            }
        },
        userIds() {
            this.refreshMyEntries();
        },
        range() {
            this.refreshMyEntries();
        },
    },
    mounted() {
        this.refreshMyEntries();
        if (this.$refs.ledFormula) {
            tippy(this.$refs.ledFormula, {
                content: 'Crédits > Réalisé + RAF',
            });
        }
        tippy('abbr');
    }
}
