import { ProjectTotals } from "@/app/types/interfaces";

export function totalsCoef(totals: ProjectTotals): number {
    // console.log(
    //     '( Crédis', (totals.vcr_days || 0) * 8,
    //     '+ PECM', parseFloat(totals.agg_pecm_hours) || 0,
    //     ') / ( Passed', totals.agg_passed_hours || 0,
    //     '+ RAF', totals.agg_raf || 0, ')'
    //     );
    // return ((totals.vcr_days || 0) * 8 + (parseFloat(totals.agg_pecm_hours) || 0))
    return ((totals.vcr_days || 0) * 8)
        / ((totals.agg_passed_hours || 0) + (totals.agg_raf || 0))
}
